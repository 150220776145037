import './App.css';
import React from "react";
import Routes from "./components/layout";

function App() {
  return (
    <Routes />
  );
}

export default App;
