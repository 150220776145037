import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import axios from "axios";
import Alert from '@mui/material/Alert';

const useStyles = makeStyles(theme => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none"
    }
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbar: {
    flexWrap: "wrap"
  },
  toolbarTitle: {
    flexGrow: 1
  },
  link: {
    margin: theme.spacing(1, 1.5)
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6)
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[700]
        : theme.palette.grey[200]
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2)
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    }
  }
}));

const AddPost = (props) => {
  const classes = useStyles();
  const [post, setPost] = useState({
    title: "",
    username: "",
    content: ""
  });
  const [status, setStatus] = useState({
    error: "",
    success: ""
  });

  useEffect(() => {
  }, []);

  const HandleAlert = () => {
    return (
      <React.Fragment>
        {status.error !== "" && (
          <Alert
            key="general"
            variant="outlined"
            severity={status.error !== "" ? "error" : "success"}
            onClose={() => setStatus(status => ({ ...status, "error": "" }))}
            dismissible="true"
          >
            {status.error}
          </Alert>
        )}

        {status.success !== "" && (
          <Alert
            key="general"
            variant="outlined"
            severity={status.success !== "" ? "success" : "error"}
            onClose={() => setStatus(status => ({ ...status, "success": "" }))}
            dismissible="true"
          >
            {status.success}
          </Alert>
        )}
      </React.Fragment>
    );
  };

  const handleChange = (event) => {
    post[event.target.name] = event.target.value;
    setPost(post => ({ ...post, [event.target.name]: event.target.value }))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    for (let key in post) {
      if (!post[key]) {
        setStatus({ error: `Please fill the ${key}.`, success: "" });
        return;
      }
    }

    axios
      .post("https://worker.akshat-goyal.workers.dev/posts", post)
      .then(res => {
        setPost({ title: "", username: "", content: "" });
        setStatus({ success: "Post Published Successfully!", error: "" });
      })
      .catch(err => {
        setStatus({ error: err.message, success: "" });
      });
  }

  return (
    <>
      <HandleAlert />
      <div>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormLabel component="legend">
                <b>Post Title</b>
                <br />
              </FormLabel>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="title"
                onChange={handleChange}
                value={post.title || ""}
              />
            </Grid>

            <Grid item xs={12}>
              <br />
              <FormLabel component="legend">
                <b>User Name</b>
                <br />
              </FormLabel>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="username"
                onChange={handleChange}
                value={post.username || ""}
              />
            </Grid>

            <Grid item xs={12}>
              <br />
              <FormLabel component="legend">
                <b>Content</b>
                <br />
              </FormLabel>
              <TextField
                variant="outlined"
                multiline
                required
                fullWidth
                name="content"
                onChange={handleChange}
                value={post.content || ""}
              />
            </Grid>
          </Grid>
          <br />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Publish Post
          </Button>
        </form>
      </div>
    </>
  );
};

export default AddPost;
