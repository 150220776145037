import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

const useStyles = makeStyles(theme => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none"
    }
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbar: {
    flexWrap: "wrap"
  },
  toolbarTitle: {
    margin: theme.spacing(1, 1.5),
    flexGrow: 1
  },
  link: {
    margin: theme.spacing(1, 1.5)
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const Navbar = (props) => {
  const classes = useStyles();

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <Typography color="textPrimary" variant="h6" className={classes.toolbarTitle}>
          Blog Posts
        </Typography>

        <Link
          variant="button"
          color="secondary"
          href="/posts"
          className={classes.link}
        >
          Posts
        </Link>
        <Link
          variant="button"
          color="secondary"
          href="/add_post"
          className={classes.link}
        >
          Add Post
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
