import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Posts = () => {
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    const getPosts = async () => {
      fetch("https://worker.akshat-goyal.workers.dev/posts")
        .then(async (resp) => {
          const postsResp = await resp.json();
          setPosts(postsResp);
        })
        .catch(err => {
          console.log(err.message);
        })
    };

    getPosts();
  }, []);

  if (posts == null) return (
    <div>
      <h1>Posts</h1>
      <p> Loading posts...</p>
    </div>
  )

  return (
    <div>
      <h1>Posts</h1>
      <br />
      {posts.map((post) => (
        <div key={post.id}>
          <h2>
            <Link to={{
              pathname: `/post/${post.id}`,
              post: {
                title: post.title,
                username: post.username,
                content: post.content
              }
            }}
            >
              {post.title}
            </Link>
          </h2>
        </div>
      ))}
    </div>
  );
};

export default Posts;