import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Layout } from "antd";
import Navbar from "./navbar";
import NotFound from "./notFound";
import Posts from './posts'
import Post from './post'
import AddPost from './addPost'

const { Content } = Layout;

const Routes = (props) => {
  return (
    <BrowserRouter>
      <Layout>
        <Navbar />
        <Content
          style={{
            padding: "50px 50px",
            marginTop: 64,
            minHeight: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <Switch>
            <Route exact path="/">
              <Posts />
            </Route>
            <Route exact path="/posts">
              <Posts />
            </Route>
            <Route exact path="/post/:id">
              <Post />
            </Route>
            <Route exact path="/add_post">
              <AddPost />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </Content>
      </Layout>
    </BrowserRouter>
  );
};

export default Routes;
