import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Post = ({ id }) => {
  const location = useLocation();
  const [post, setPost] = useState({});

  useEffect(() => {
    const getPost = async () => {
      if (location.post)
        setPost(location.post);
    };

    getPost();
  }, [location]);

  if (!Object.keys(post).length) return <div />;

  return (
    <div>
      <h1>{post.title}</h1>
      <p>
        <em>{post.username}</em>
      </p>
      <p>{post.content}</p>
    </div>
  );
};

export default Post;